import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from '../../assets'
import styled from 'styled-components'

const Banner = props => (
  <StaticQuery
    query={graphql`
      query {
        featureBack: file(name: {eq: "tech-feature-back"}) {
          childImageSharp {
            gatsbyImageData(width: 100 quality: 100)
          }
        }
      }
    `}
    render={data => { 
      const {
        image,
        heading,
        subheading,
        description,
        description2,
        featuresHeading,
        features
      } = props
      const featureBack = data.featureBack.childImageSharp.gatsbyImageData

      return (
        <BannerContent container>
          <BannerTop container item>
            <BannerTopLeft item xs={12} lg={6}>
              <TetraLogo
                image={image.gatsbyImageData}
                alt={heading}
              />
              <h1>{heading}</h1>
              <h2>{subheading}</h2>
              <p>{description}</p>
              <p>{description2}</p>
            </BannerTopLeft>
            <BannerTopRight item lg={6}>
              <TetraLogo
                image={image.gatsbyImageData}
                alt={heading}
              />
            </BannerTopRight>
          </BannerTop>
          <BannerBottom container item>
            <h2>{featuresHeading}</h2>
            <Features>
              {features.map((feature, i) => (
                <Feature key={i} image={featureBack}
                >
                  <FeatureImage>
                    <GatsbyImage
                      image={feature.image.childImageSharp.gatsbyImageData}
                      alt={`${ feature.title } - Tetra Trust Company`}
                    />
                  </FeatureImage>
                  <FeatureDescription>
                    <h5>{feature.title}</h5>
                    <p>{feature.description}</p>
                  </FeatureDescription>
                </Feature>
              ))}
            </Features>
          </BannerBottom>
        </BannerContent>
      )
    }}
  />
)

const BannerContent = styled(Grid)`
  align-items: space-between;
  padding: 0 20px 10px;
  background-image: linear-gradient(
    135deg,
    ${ colors.TETRA_BANNER_LIGHT } 0%,
    ${ colors.TETRA_BANNER_LIGHT } 30%,
    ${ colors.TETRA_BANNER_DARK } 70%,
    ${ colors.TETRA_BANNER_DARK } 100%
  );

  ${ mediaQueries.SM_UP } {
    padding: 0 30px 40px;
  }

  ${ mediaQueries.LG_UP } {
    padding: 0 75px 75px;
  }
`

const BannerTop = styled(Grid)`
  justify-content: space-between;
`

const TetraLogo = styled(GatsbyImage)`
  opacity: 0.25;
`

const BannerTopLeft = styled(Grid)`
  position: relative;
  padding: 30px 20px 10px;

  ${ mediaQueries.LG_UP } {
    max-width: 630px;
    padding: 85px 0 0;
  }

  h1,
  h2,
  p {
    color: ${ colors.TETRA_WHITE };

    ${ mediaQueries.SM_UP } {
      width: 50%;
    }

    ${ mediaQueries.LG_UP } {
      width: 100%;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    ${ mediaQueries.SM_UP } {
      font-size: 24px;
      line-height: 32px;
      margin: 30px 0 40px;
    }
  }

  p {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 24px;
    color: ${ colors.TETRA_WHITE };

    ${ mediaQueries.SM_UP } {
      margin: 25px 0 20px;
      font-size: 16px;
    }
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 65%;
    margin-right: -20px;

    ${ mediaQueries.SM_UP } {
      max-width: 450px;
      margin-right: -30px;
    }

    ${ mediaQueries.LG_UP } {
      display: none;
    }
  }
`

const BannerTopRight = styled(Grid)`
  display: none;

  ${ mediaQueries.LG_UP } {
    display: flex;
    justify-content: flex-end;
    margin-right: -75px;
    padding: 0;
    text-align: right;
  }

  .gatsby-image-wrapper {
    display: inline-block;
    width: 100%;
    max-width: 420px;
    height: auto;
  }
`

const BannerBottom = styled(Grid)`
  justify-content: center;

  ${ mediaQueries.LG_UP } {
    margin-top: -110px;
  }

  h2,
  h5,
  p {
    color: ${ colors.TETRA_WHITE };
    text-align: center;
  }

  h2 {
    margin: 50px 0 25px;

    ${ mediaQueries.LG_UP } {
      margin: 0 0 40px;
    }
  }
`

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${ mediaQueries.SM_UP } {
    flex-direction: row;
    justify-content: center;
  }

  ${ mediaQueries.MD_UP } {
    flex-wrap: nowrap;
  }
`

const Feature = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin: 0 0 30px;
  padding: 40px 20px 30px;
  background-position: top center;
  background-size: cover;

  ${ mediaQueries.SM_UP } {
    width: 300px;
    height: 340px;
    margin: 0 15px 30px;
  }

  ${ mediaQueries.MD_UP } {
    width: 200px;
    height: 530px;
  }

  ${ mediaQueries.LG_UP } {
    width: 300px;
    min-width: 250px;
    height: 412px;
    margin: 0 15px;
    padding: 0 15px;
  }
`

const FeatureImage = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 20px;

  ${ mediaQueries.SM_UP } {
    height: 170px;
    padding: 0;
  }

  ${ mediaQueries.MD_UP } {
    height: 120px;
  }

  ${ mediaQueries.LG_UP } {
    height: 170px;
  }

  .gatsby-image-wrapper {
    width: 70px;

    ${ mediaQueries.SM_UP } {
      width: 80px;
    }
  }
`

const FeatureDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  ${ mediaQueries.LG_UP } {
    height: 242px;
  }

  h5 {
    margin: 0 0 5px;
    font-size: 20px;
    line-height: 24px;

    ${ mediaQueries.MD_UP } {
      margin: 0;
      height: 115px;
      font-size: 26px;
      line-height: 32px;
    }

    ${ mediaQueries.LG_UP } {
      height: 90px;
      font-size: 32px;
      line-height: 40px;
    }
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }
`

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuresHeading: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
}

export default Banner
