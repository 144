import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/technology/banner"
import Features from "../components/technology/features"
import SignUp from "../components/technology/sign-up"

const TechnologyPage = ({ data }) => {
  const { technology } = data.markdownRemark
  
  return (
    <Layout>
      <Seo
        title={technology.seoTitle}
        description={technology.seoDescription}
        image={technology.seoImage.publicURL}
      />

      <Banner
        image={technology.bannerImage.childImageSharp}
        heading={technology.bannerHeading}
        subheading={technology.bannerSubheading}
        description={technology.bannerDescription}
        description2={technology.bannerDescription2}
        featuresHeading={technology.bannerFeaturesHeading}
        features={technology.bannerFeatures}
      />

      <Features
        heading={technology.featuresHeading}
        description={technology.featuresDescription}
        cta={technology.featuresCta}
        features={technology.features}
      />

      <SignUp
        heading={technology.signUpHeading}
        cta={technology.signUpCta}
      />
    </Layout>
  )
}

export default TechnologyPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/technology" } }) {
      technology: frontmatter {
        seoTitle
        seoDescription
        seoImage {
          publicURL
        }
        bannerImage {
          childImageSharp {
            gatsbyImageData(width: 650 quality: 100)
          }
        }
        bannerHeading
        bannerSubheading
        bannerDescription
        bannerDescription2
        bannerFeaturesHeading
        bannerFeatures {
          image {
            childImageSharp {
              gatsbyImageData(width: 100 quality: 100)
            }
          }
          title
          description
        }
        featuresHeading
        featuresDescription
        featuresCta
        features {
          title
          description
          bgLoad
        }
        signUpHeading
        signUpCta
      }
    }
  }
`
