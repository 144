import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Container from "../container"
import Button from "../button-link"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../../assets"
import styled from "styled-components"

const Features = props => (
  <StaticQuery
    query={graphql`
      fragment FeatureBg on File {
        name
        childImageSharp {
          gatsbyImageData(width: 500)
        }
      }
      query {
        featureMobile1: file(name: {eq: "tech-feature-mobile-1"}) {
          ...FeatureBg
        }
        featureMobile2: file(name: {eq: "tech-feature-mobile-2"}) {
          ...FeatureBg
        }
        featureMobile3: file(name: {eq: "tech-feature-mobile-3"}) {
          ...FeatureBg
        }
        feature1: file(name: {eq: "tech-feature-1"}) {
          ...FeatureBg
        }
        feature2: file(name: {eq: "tech-feature-2"}) {
          ...FeatureBg
        }
        feature3: file(name: {eq: "tech-feature-3"}) {
          ...FeatureBg
        }
      }
    `}
    render={data => { 
      const { heading, description, cta, features } = props

      return (
        <Container background={colors.TETRA_GREY}>
          <FeaturesContainer container>
            <Heading item xs={12}>
              <h2>{heading}</h2>
              <h3>{description}</h3>
              <Button
                buttontype="white"
                to="/#get-started"
                text={cta}
                margin="25px auto 0"
              />
            </Heading>
            <TechFeatures item xs={12}>
              {features.map((feature, i) => (
                <div key={i}>
                  <FeatureMobile
                    className={`${ feature.bgLoad } mobile`}
                    image={data['featureMobile' + (i + 1)].childImageSharp.gatsbyImageData}
                  >
                    <h4>{feature.title}</h4>
                    <p>{feature.description}</p>
                  </FeatureMobile>
                  <FeatureDesktop
                    className={`${ feature.bgLoad } desktop`}
                    image={data['feature' + (i + 1)].childImageSharp.gatsbyImageData}
                  >
                    <h4>{feature.title}</h4>
                    <p>{feature.description}</p>
                  </FeatureDesktop>
                </div>
              ))}
            </TechFeatures>
          </FeaturesContainer>
        </Container>
      )
    }}
  />
)

const FeaturesContainer = styled(Grid)`
  ${ mediaQueries.CONTAINER_SIZES }
  justify-content: center;
  padding: 40px 20px;

  ${ mediaQueries.SM_UP } {
    padding: 40px 30px;
  }

  ${ mediaQueries.MD_UP } {
    padding: 60px 30px 75px;
  }

  ${ mediaQueries.MD_UP } {
    justify-content: flex-start;
  }
`

const Heading = styled(Grid)`
  text-align: center;

  ${ mediaQueries.MD_UP } {
    margin: 0 0 50px;
  }

  h2 {
    max-width: 290px;
    margin: 0 auto 5px;

    ${ mediaQueries.SM_UP } {
      max-width: none;
      margin: 0 auto 20px;
    }
  }

  h3 {
    max-width: 850px;
    margin: 0 auto;
  }
`

const TechFeatures = styled(Grid)`
  max-width: 290px;
  margin: 40px auto 0;

  ${ mediaQueries.MD_UP } {
    max-width: 850px;
    margin: 0 auto;
  }

  div.mobile,
  div.desktop {
    background: ${ colors.TETRA_BLACK };

    h4,
    p {
      color: ${ colors.TETRA_WHITE };
    }
  }

  div.mobile {
    width: 145px;
    height: 210px;

    h4,
    p {
      line-height: 20px;
    }

    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  div.desktop {
    width: 425px;
    height: 179px;

    h4 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  div.mobile.tech-feature-3 {
    width: 290px;
    height: 160px;
  }

  div.desktop.tech-feature-3 {
    width: 850px;
  }

  div.mobile.tech-feature-3,
  div.desktop.tech-feature-3 {
    background: ${ colors.TETRA_WHITE };

    h4,
    p {
      color: ${ colors.TETRA_BLACK };
    }
  }
`

const FeatureMobile = styled(BackgroundImage)`
  float: left;
  padding: 30px 15px;

  ${ mediaQueries.MD_UP } {
    display: none;
  }
`

const FeatureDesktop = styled(BackgroundImage)`
  display: none;
  float: left;
  padding: 40px;

  ${ mediaQueries.MD_UP } {
    display: block;
  }
`

Features.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
}

export default Features
